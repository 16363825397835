@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");

html {
	height: 100%;
	overflow-y: overlay;
}

body {
	margin: 0;
	height: 100%;
	background-color: #f2f2f2 !important;

	#root {
		height: 100%;
	}

	main {
		width: 100%;
	}

	font-family: "Work Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
	}

	p,
	span {
		margin: 0;
		padding: 0;
	}

	::-webkit-scrollbar {
		width: 0.75em;
		height: 0.75em;
		position: absolute;
		right: 0;
		background-color: transparent;
	}

	::-webkit-scrollbar-thumb {
		background-color: #8a94a6;
		border-radius: 20px;
	}

	::-webkit-scrollbar :hover {
		overflow: auto;
		cursor: pointer;
		padding-right: 1px;
	}

	.swal2-container {
		z-index: 1300 !important;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
