@import "../../utils/colors";

._login_main {
	height: 100vh;
	width: 100%;
	background-color: $bg_accent;

	display: flex;
	align-items: center;
	justify-content: center;

	._form_container {
		background-color: $white;
		padding: 20px;
		display: flex;
		flex-direction: column;
		gap: 20px;

		h2 {
			text-align: center;
		}
	}
}
