$primary: #e7494c;
$primary_fade: #cc1e3585;
$secondary: #3c3c3b;
$textGrey: #8a94a6;
$lightGrey: #efefef;
$mediumGrey: #c4c4c4;
$dark: #252837;
$white: #fff;
$primary_fade: #a6e6e1;
$success: #388e3c;
$danger: #f44336;
$bg_accent: #f2f2f2;

:export {
	primary_fade: $primary_fade;
	primaryColor: $primary;
	secondaryColor: $secondary;
	textGrey: $textGrey;
	dark: $dark;
	lightGrey: $lightGrey;
	mediumGrey: $mediumGrey;
	white: $white;
	success: $success;
	danger: $danger;
	bg_accent: $bg_accent;
}
