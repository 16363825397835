._risk_matrix_main {
	._content_container {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		@media (max-width: 1168px) {
			width: 100%;
			display: block;
		}
		._donut_container {
			width: 46%;
			background-color: white;
			border-radius: 15px;
			@media (max-width: 1168px) {
				width: 70%;
				margin-bottom: 2rem;
			}
		}

		._legend_card_container {
			display: grid;
			grid-template-columns: 50% 50%;
			flex-wrap: wrap;
			gap: 20px;
		}
	}
}
